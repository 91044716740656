<template>
    <div>
      <b-modal
        @hidden="resetModal"
        ref="modal-letter-support-create"
        centered
        hide-footer
        size="lg"
        :no-close-on-backdrop="true"
        content-class="overflow-hidden"
      >
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark"><i class="uil uil-file-alt text-primary"></i> New Letter Of Support</h5>
      </template>
        <div class="row align-items-center">
          <div class="col-12" >
             <div class="mb-2 row">
              <label class="col-12 col-form-label">Applicant</label>
              <div class="col-12">

                <multiselect
                  autocomplete="off"
                  :disabled="disabled"
                  v-model="formData.applicant"
                  :options="applicantList"
                  label="company_name"
                  placeholder="Select Applicant"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  class="font-size-15"
                  :class="{
                    'is-invalid': modalSubmit && $v.formData.applicant.$error,
                  }"
                  :show-on-select="true"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="updateApplicantInfo"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.id !== '-1'">
                      <span class="option__title">
                        {{ props.option.company_name }}
                      </span>
                    </span>
                    <span v-else>{{ props.option.company_name }} </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.id !== '-1'">
                      <span class="option__title">
                        {{ props.option.company_name }}
                      </span>
                    </span>
                    <span v-else>{{ props.option.company_name }}</span>
                  </template>
                  <span slot="noResult">Oops! No Applicant found. </span>
                </multiselect>
                <div
                  v-if="modalSubmit && $v.formData.applicant.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.applicant.required"
                    >Applicant is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Document Date</label>
              <div class="col-12">
                <date-picker
                    v-model="formData.document_date"
                    type="date"
                    value-type="format"
                    format="MMM DD, YYYY"
                    placeholder="Date"
                    :disabled="disabled"
                    :disabled-date="notBeforeToday"
                    :editable="false"
                  ></date-picker>
                  <div
                  v-if="modalSubmit && $v.formData.document_date.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.document_date.required"
                    >Document Date is required.</span
                  >
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Type of Letter of Support</label>
              <div class="col-12">
                <span v-for="(v,index) in type_of_letter_support" :key="index">
                <input type="radio" v-model="formData.type" :value="v.value" class="btn-check" name="document_type" :id="index" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formData.type==v.value}" :for="index">{{v.name}}</label>
                </span>
                <div
                  v-if="modalSubmit && $v.formData.type.$error"
                  class="invalid-feedback d-block"
                >
                  <span v-if="!$v.formData.type.required"
                    >Document type is required.</span
                  >
                </div>
              </div>
            </div>
            
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Subject</label>
              <div class="col-12">

                <input
                    v-model.trim="formData.subject"
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                    name="subject"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.subject.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.subject.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.subject.required"
                       >Subject is required.</span
                       >
                 </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Documents (Optional) <small class="d-block text-muted fw-normal">If you manually upload the document, the bot will not automatically generate it.</small></label>
              <div class="col-12">
                <div class="row mb-0">
                <div
                    class="col-xl-6"
                    v-for="(value, index) in formData.files"
                    :key="index"
                  >
                    <div class="card border shadow-none  border-2 bg-white mb-lg-0 mb-lg-2">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-4">
                            <div class="avatar-sm">
                              <span
                                class="avatar-title bg-soft-primary text-primary font-size-22 rounded-circle"
                                :class="{
                                  'bg-soft-success text-success':
                                    value.status == 1,
                                }"
                              >
                              <i class="uil uil-file-alt"></i>
                              </span>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center">
                            <div class="">
                              <div class="d-flex w-100">
                                <input type="text" class="form-control font-size-15 mb-1 py-1 fw-medium bg-light border-bottom border-dark" v-model="value.name" v-if="index>9" >
                                <h5 class="font-size-15 mb-1 fw-medium" v-else>
                                {{ value.name }}
                                </h5>
                              </div>
                              <p class="text-success" v-if="value.status == 1">
                                <i
                                  class="mdi mdi-check-circle text-success"
                                ></i>
                                Completed
                                <a
                                  :href="value.files"
                                  class="ms-1"
                                  v-b-tooltip.hover
                                  :title="value.file_name"
                                  v-if="value.files"
                                  target="_blank"
                                >
                                  <i class="uil uil-import font-size-15"></i>
                                  {{ value.file_name }} ({{ value.file_size }})
                                </a>
                                <a href="javascript:void(0)" class="text-danger ms-1" v-if="value.status == 1" @click="
                                    value.status=0,
                                    value.files= '',
                                    value.file_name= '',
                                    value.file_size= ''
                                "><span><i class="mdi mdi-trash-can-outline font-size-16"></i> Remove</span>
                                </a>
                              </p>
                              <p class="text-warning" v-if="value.status == 2">
                                <i
                                  class="mdi mdi-clock-time-nine-outline text-warning"
                                ></i>
                                Uploading
                                <span class="text-muted ms-1"
                                  >{{ value.file_name }} ({{
                                    value.file_size
                                  }})</span
                                >
                              </p>
                              <p class="text-muted" v-if="value.status == 0">
                                <i class="mdi mdi-alert-circle text-danger"></i>
                                Pending
                              </p>

                              <!-- <div class="placeholder-glow">
                                          <span class="placeholder col-12 rounded"></span>
                                         </div> -->
                              <div v-if="buttonLoading && value.status == 2">
                                <div
                                  class="Animated progress rounded bg-white"
                                  style="height: 26px"
                                  role="progressbar"
                                  :aria-valuenow="uploadProgress"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13"
                                    :style="`width: ${uploadProgress}%`"
                                  >
                                    {{ uploadProgress }}%
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <input
                                  class="form-control mb-0"
                                  accept="application/pdf"
                                  type="file"
                                  :disabled="buttonLoading"
                                  :id="value.id"
                                  @change="onChangePDF"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Notes</label>
              <div class="col-12">
                <textarea class="form-control" placeholder="Optional" id="notes" v-model.trim="formData.notes" rows="3"></textarea>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-12 col-lg col-form-label">Status</label>
              <div class="col-12">
                <select class="form-select" v-model="formData.status" :disabled="disabled">
                  <option value="draft">Draft</option>
                  <option value="sent">Sent</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
           
            
            
            <hr>
           
            <div class="d-grid gap-2 d-md-block text-end">
              <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-letter-support-create'].hide()">Cancel</button>
              
              <button type="button" class="btn fw-medium btn-info" @click="addRequest" :disabled="modalLoading">
                <span v-if="modalLoading"> Submitting...</span>
                <span v-else> Submit </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          
        </div>
      </b-modal>
      <b-modal
        @hidden="resetModal"
        ref="modal-letter-support-edit"
        centered
        hide-footer
        size="lg"
        :no-close-on-backdrop="true"
        content-class="overflow-hidden"
      >
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark"><i class="uil uil-file-alt text-primary"></i> Edit Letter Of Support</h5>
      </template>
        <div class="row align-items-center">
          <div class="col-12" >
             <div class="mb-2 row">
              <label class="col-12 col-form-label">Applicant</label>
              <div class="col-12">
                <multiselect
                  autocomplete="off"
                  :disabled="true"
                  v-model="formEditData.applicant"
                  :options="applicantList"
                  label="company_name"
                  placeholder="Select Applicant"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  class="font-size-15"
                  :class="{
                    'is-invalid': modalSubmit && $v.formEditData.applicant.$error,
                  }"
                  :show-on-select="true"
                  @open="isOpen = true"
                  @close="isOpen = false"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.id !== '-1'">
                      <span class="option__title">
                        {{ props.option.company_name }}
                      </span>
                    </span>
                    <span v-else>{{ props.option.company_name }} </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.id !== '-1'">
                      <span class="option__title">
                        {{ props.option.company_name }}
                      </span>
                    </span>
                    <span v-else>{{ props.option.company_name }}</span>
                  </template>
                  <span slot="noResult">Oops! No Applicant found. </span>
                </multiselect>
                <div
                  v-if="modalSubmit && $v.formEditData.applicant.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formEditData.applicant.required"
                    >Applicant is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Document Date</label>
              <div class="col-12">
                <date-picker
                    v-model="formEditData.document_date"
                    type="date"
                    value-type="format"
                    format="MMM DD, YYYY"
                    placeholder="Date"
                    :disabled="disabled"
                    :disabled-date="notBeforeToday"
                    :editable="false"
                  ></date-picker>
                  <div
                  v-if="modalSubmit && $v.formEditData.document_date.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formEditData.document_date.required"
                    >Document Date is required.</span
                  >
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Type of Letter of Support</label>
              <div class="col-12">
                <span v-for="(v,index) in type_of_letter_support" :key="index">
                <input type="radio" v-model="formEditData.type" :value="v.value" class="btn-check" name="document_type" :id="index" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formEditData.type==v.value}" :for="index">{{v.name}}</label>
                </span>
                <div
                  v-if="modalSubmit && $v.formEditData.type.$error"
                  class="invalid-feedback d-block"
                >
                  <span v-if="!$v.formEditData.type.required"
                    >Document type is required.</span
                  >
                </div>
              </div>
            </div>
            
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Subject</label>
              <div class="col-12">

                <input
                    v-model.trim="formEditData.subject"
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                    name="subject"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.subject.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formEditData.subject.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.subject.required"
                       >Subject is required.</span
                       >
                 </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Documents (Optional) <small class="d-block text-muted fw-normal">If you manually upload the document, the bot will not automatically generate it.</small></label>
              <div class="col-12">
                <div class="row mb-0">
                <div
                    class="col-xl-6"
                    v-for="(value, index) in formEditData.attachment"
                    :key="index"
                  >
                    <div class="card border shadow-none  border-2 bg-white mb-lg-0 mb-lg-2">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-4">
                            <div class="avatar-sm">
                              <span
                                class="avatar-title bg-soft-primary text-primary font-size-22 rounded-circle"
                                :class="{
                                  'bg-soft-success text-success':
                                    value.status == 1,
                                }"
                              >
                              <i class="uil uil-file-alt"></i>
                              </span>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center">
                            <div class="">
                              <div class="d-flex w-100">
                                <input type="text" class="form-control font-size-15 mb-1 py-1 fw-medium bg-light border-bottom border-dark" v-model="value.name" v-if="index>9" >
                                <h5 class="font-size-15 mb-1 fw-medium" v-else>
                                {{ value.name }}
                                </h5>
                              </div>
                              <p class="text-success" v-if="value.status == 1">
                                <i
                                  class="mdi mdi-check-circle text-success"
                                ></i>
                                Completed
                                <a
                                  :href="value.files"
                                  class="ms-1"
                                  v-b-tooltip.hover
                                  :title="value.file_name"
                                  v-if="value.files"
                                  target="_blank"
                                >
                                  <i class="uil uil-import font-size-15"></i>
                                  {{ value.file_name }} ({{ value.file_size }})
                                </a>
                                <a href="javascript:void(0)" class="text-danger ms-1" v-if="value.status == 1" @click="
                                    value.status=0,
                                    value.files= '',
                                    value.file_name= '',
                                    value.file_size= ''
                                "><span><i class="mdi mdi-trash-can-outline font-size-16"></i> Remove</span>
                                </a>
                              </p>
                              <p class="text-warning" v-if="value.status == 2">
                                <i
                                  class="mdi mdi-clock-time-nine-outline text-warning"
                                ></i>
                                Uploading
                                <span class="text-muted ms-1"
                                  >{{ value.file_name }} ({{
                                    value.file_size
                                  }})</span
                                >
                              </p>
                              <p class="text-muted" v-if="value.status == 0">
                                <i class="mdi mdi-alert-circle text-danger"></i>
                                Pending
                              </p>

                              <!-- <div class="placeholder-glow">
                                          <span class="placeholder col-12 rounded"></span>
                                         </div> -->
                              <div v-if="buttonLoading && value.status == 2">
                                <div
                                  class="Animated progress rounded bg-white"
                                  style="height: 26px"
                                  role="progressbar"
                                  :aria-valuenow="uploadProgress"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13"
                                    :style="`width: ${uploadProgress}%`"
                                  >
                                    {{ uploadProgress }}%
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <input
                                  class="form-control mb-0"
                                  accept="application/pdf"
                                  type="file"
                                  :disabled="buttonLoading"
                                  :id="value.id"
                                  @change="onChangePDF"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Completed Documents<small class="d-block text-muted fw-normal">Please upload the completed document, once the document process is completed.</small></label>
              <div class="col-12">
                <div class="row mb-0">
                <div
                    class="col-xl-6"
                    v-for="(value, index) in formEditData.completed_attachment"
                    :key="index"
                  >
                    <div class="card border shadow-none  border-2 bg-white mb-lg-0 mb-lg-2">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-4">
                            <div class="avatar-sm">
                              <span
                                class="avatar-title bg-soft-primary text-primary font-size-22 rounded-circle"
                                :class="{
                                  'bg-soft-success text-success':
                                    value.status == 1,
                                }"
                              >
                              <i class="uil uil-file-alt"></i>
                              </span>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center">
                            <div class="">
                              <div class="d-flex w-100">
                                <input type="text" class="form-control font-size-15 mb-1 py-1 fw-medium bg-light border-bottom border-dark" v-model="value.name" v-if="index>9" >
                                <h5 class="font-size-15 mb-1 fw-medium" v-else>
                                {{ value.name }}
                                </h5>
                              </div>
                              <p class="text-success" v-if="value.status == 1">
                                <i
                                  class="mdi mdi-check-circle text-success"
                                ></i>
                                Completed
                                <a
                                  :href="value.files"
                                  class="ms-1"
                                  v-b-tooltip.hover
                                  :title="value.file_name"
                                  v-if="value.files"
                                  target="_blank"
                                >
                                  <i class="uil uil-import font-size-15"></i>
                                  {{ value.file_name }} ({{ value.file_size }})
                                </a>
                                <a href="javascript:void(0)" class="text-danger ms-1" v-if="value.status == 1" @click="
                                    value.status=0,
                                    value.files= '',
                                    value.file_name= '',
                                    value.file_size= ''
                                "><span><i class="mdi mdi-trash-can-outline font-size-16"></i> Remove</span>
                                </a>
                              </p>
                              <p class="text-warning" v-if="value.status == 2">
                                <i
                                  class="mdi mdi-clock-time-nine-outline text-warning"
                                ></i>
                                Uploading
                                <span class="text-muted ms-1"
                                  >{{ value.file_name }} ({{
                                    value.file_size
                                  }})</span
                                >
                              </p>
                              <p class="text-muted" v-if="value.status == 0">
                                <i class="mdi mdi-alert-circle text-danger"></i>
                                Pending
                              </p>

                              <!-- <div class="placeholder-glow">
                                          <span class="placeholder col-12 rounded"></span>
                                         </div> -->
                              <div v-if="buttonLoading && value.status == 2">
                                <div
                                  class="Animated progress rounded bg-white"
                                  style="height: 26px"
                                  role="progressbar"
                                  :aria-valuenow="uploadProgress"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13"
                                    :style="`width: ${uploadProgress}%`"
                                  >
                                    {{ uploadProgress }}%
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <input
                                  class="form-control mb-0"
                                  accept="application/pdf"
                                  type="file"
                                  :disabled="buttonLoading"
                                  :id="value.id"
                                  @change="onChangeCompletePDF"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Recent Notes</label>
              <div class="col-12" v-if="formEditData.note_logs.length">
                <ol class="activity-feed bg-light mb-0 p-4 rounded-3" data-simplebar style="max-height: 336px;">
                  <li class="feed-item" v-for="(value, index) in formEditData.note_logs" :key="index">
                      <div class="feed-item-list">
                          <p class="text-muted mb-1 font-size-13 text-capitalize">
                            {{ convertDate2DayWeekandDate(value.date_created)}} 
                          </p>
                          <p class="mt-0 mb-0">
                             {{value.notes}}
                          </p> 
                          <em class="mt-1 font-size-13 text-muted">Updated by {{value.created_by}} 
                          </em> 
                      </div>
                  </li>
                </ol>
              </div>
              <div class="col-12" v-else>
                <div class="text-center bg-light mb-0 p-4 rounded-3 text-muted">
                    No Data Available
                </div>
              </div>
            </div>
            
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Add New Note</label>
              <div class="col-12">
                <textarea class="form-control" placeholder="Optional" id="notes" v-model.trim="formEditData.notes" rows="3"></textarea>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-12 col-lg col-form-label">Status</label>
              <div class="col-12">
                <select class="form-select" v-model="formEditData.status" :disabled="disabled">
                  <option value="draft">Draft</option>
                  <option value="sent">Sent</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
            <hr>
            <div class="d-grid gap-2 d-md-block text-end">
              <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-letter-support-edit'].hide()">Cancel</button>
              
              <button type="button" class="btn fw-medium btn-info" @click="updateRequest" :disabled="modalLoading">
                <span v-if="modalLoading"> Updating...</span>
                <span v-else> Update </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          
        </div>
      </b-modal>
      <b-modal
        @hidden="resetModal"
        ref="modal-term-sheet-create"
        centered
        hide-footer
        size="lg"
        :no-close-on-backdrop="true"
        content-class="overflow-hidden"
      >
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark"><i class="uil uil-file-alt text-primary"></i> New Term Sheet</h5>
      </template>
        <div class="row align-items-center">
          <div class="col-12" >
             <div class="mb-2 row">
              <label class="col-12 col-form-label">Factoring Application</label>
              <div class="col-12">
                <multiselect
                  autocomplete="off"
                  :disabled="disabled"
                  v-model="formData2.factoring_application"
                  :options="factoringApplicationList"
                  label="keyword"
                  placeholder="Select Factoring Application"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  class="font-size-15"
                  :class="{
                    'is-invalid': modalSubmit && $v.formData2.factoring_application.$error,
                  }"
                  :show-on-select="true"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="updateFactoringApplication"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.id !== '-1'">
                      <span class="option__title">
                        #{{ props.option.application_id }} - {{ props.option.applicant.company_name }}
                      </span>
                    </span>
                    <span v-else> #{{ props.option.application_id }} - {{ props.option.applicant.company_name }} </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.id !== '-1'">
                      <span class="option__title">
                        #{{ props.option.application_id }} - {{ props.option.applicant.company_name }}
                      </span>
                    </span>
                    <span v-else>#{{ props.option.application_id }} - {{ props.option.applicant.company_name }}</span>
                  </template>
                  <span slot="noResult">Oops! No Factoring Application found. </span>
                </multiselect>
                <div
                  v-if="modalSubmit && $v.formData2.factoring_application.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData2.factoring_application.required"
                    >Factoring Application is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Document Date</label>
              <div class="col-12">
                <date-picker
                    v-model="formData2.document_date"
                    type="date"
                    value-type="format"
                    format="MMM DD, YYYY"
                    placeholder="Date"
                    :disabled="disabled"
                    :disabled-date="notBeforeToday"
                    :editable="false"
                  ></date-picker>
                  <div
                  v-if="modalSubmit && $v.formData2.document_date.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData2.document_date.required"
                    >Document Date is required.</span
                  >
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Type of Term Sheet</label>
              <div class="col-12">
                <span v-for="(v,index) in type_for_term_sheet" :key="index">
                <input type="radio" v-model="formData2.type" :value="v.value" class="btn-check" name="document_type" :id="index" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formData2.type==v.value}" :for="index">{{v.name}}</label>
                </span>
                <div
                  v-if="modalSubmit && $v.formData2.type.$error"
                  class="invalid-feedback d-block"
                >
                  <span v-if="!$v.formData2.type.required"
                    >Document type is required.</span
                  >
                </div>
              </div>
            </div>
            
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Subject</label>
              <div class="col-12">

                <input
                    v-model.trim="formData2.subject"
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                    name="subject"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData2.subject.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData2.subject.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData2.subject.required"
                       >Subject is required.</span
                       >
                 </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Documents (Optional) <small class="d-block text-muted fw-normal">If you manually upload the document, the bot will not automatically generate it.</small></label>
              <div class="col-12">
                <div class="row mb-0">
                <div
                    class="col-xl-6"
                    v-for="(value, index) in formData2.files"
                    :key="index"
                  >
                    <div class="card border shadow-none  border-2 bg-white mb-lg-0 mb-lg-2">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-4">
                            <div class="avatar-sm">
                              <span
                                class="avatar-title bg-soft-primary text-primary font-size-22 rounded-circle"
                                :class="{
                                  'bg-soft-success text-success':
                                    value.status == 1,
                                }"
                              >
                              <i class="uil uil-file-alt"></i>
                              </span>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center">
                            <div class="">
                              <div class="d-flex w-100">
                                <input type="text" class="form-control font-size-15 mb-1 py-1 fw-medium bg-light border-bottom border-dark" v-model="value.name" v-if="index>9" >
                                <h5 class="font-size-15 mb-1 fw-medium" v-else>
                                {{ value.name }}
                                </h5>
                              </div>
                              <p class="text-success" v-if="value.status == 1">
                                <i
                                  class="mdi mdi-check-circle text-success"
                                ></i>
                                Completed
                                <a
                                  :href="value.files"
                                  class="ms-1"
                                  v-b-tooltip.hover
                                  :title="value.file_name"
                                  v-if="value.files"
                                  target="_blank"
                                >
                                  <i class="uil uil-import font-size-15"></i>
                                  {{ value.file_name }} ({{ value.file_size }})
                                </a>
                                <a href="javascript:void(0)" class="text-danger ms-1" v-if="value.status == 1" @click="
                                    value.status=0,
                                    value.files= '',
                                    value.file_name= '',
                                    value.file_size= ''
                                "><span><i class="mdi mdi-trash-can-outline font-size-16"></i> Remove</span>
                                </a>
                              </p>
                              <p class="text-warning" v-if="value.status == 2">
                                <i
                                  class="mdi mdi-clock-time-nine-outline text-warning"
                                ></i>
                                Uploading
                                <span class="text-muted ms-1"
                                  >{{ value.file_name }} ({{
                                    value.file_size
                                  }})</span
                                >
                              </p>
                              <p class="text-muted" v-if="value.status == 0">
                                <i class="mdi mdi-alert-circle text-danger"></i>
                                Pending
                              </p>

                              <!-- <div class="placeholder-glow">
                                          <span class="placeholder col-12 rounded"></span>
                                         </div> -->
                              <div v-if="buttonLoading && value.status == 2">
                                <div
                                  class="Animated progress rounded bg-white"
                                  style="height: 26px"
                                  role="progressbar"
                                  :aria-valuenow="uploadProgress"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13"
                                    :style="`width: ${uploadProgress}%`"
                                  >
                                    {{ uploadProgress }}%
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <input
                                  class="form-control mb-0"
                                  accept="application/pdf"
                                  type="file"
                                  :disabled="buttonLoading"
                                  :id="value.id"
                                  @change="onChangePDF2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Notes</label>
              <div class="col-12">
                <textarea class="form-control" placeholder="Optional" id="notes" v-model.trim="formData2.notes" rows="3"></textarea>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-12 col-lg col-form-label">Status</label>
              <div class="col-12">
                <select class="form-select" v-model="formData2.status" :disabled="disabled">
                  <option value="draft">Draft</option>
                  <option value="sent">Sent</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
           
            
            
            <hr>
           
            <div class="d-grid gap-2 d-md-block text-end">
              <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-term-sheet-create'].hide()">Cancel</button>
              
              <button type="button" class="btn fw-medium btn-info" @click="addRequest2" :disabled="modalLoading">
                <span v-if="modalLoading"> Submitting...</span>
                <span v-else> Submit </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          
        </div>
      </b-modal>
      <b-modal
        @hidden="resetModal"
        ref="modal-term-sheet-edit"
        centered
        hide-footer
        size="lg"
        :no-close-on-backdrop="true"
        content-class="overflow-hidden"
      >
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark"><i class="uil uil-file-alt text-primary"></i> Edit Letter Of Support</h5>
      </template>
        <div class="row align-items-center">
          <div class="col-12" >
             <div class="mb-2 row">
              <label class="col-12 col-form-label">Factoring Application</label>
              <div class="col-12">

                <multiselect
                  autocomplete="off"
                  :disabled="true"
                  v-model="formEditData2.factoring_application"
                  :options="factoringApplicationList"
                  label="keyword"
                  placeholder="Select Applicant"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  class="font-size-15"
                  :class="{
                    'is-invalid': modalSubmit && $v.formEditData2.factoring_application.$error,
                  }"
                  :show-on-select="true"
                  @open="isOpen = true"
                  @close="isOpen = false"
                >
                <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.id !== '-1'">
                      <span class="option__title">
                       {{ props.option.applicant.company_name }}
                      </span>
                    </span>
                    <span v-else> {{ props.option.applicant.company_name }} </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.id !== '-1'">
                      <span class="option__title">
                        {{ props.option.applicant.company_name }}
                      </span>
                    </span>
                    <span v-else> {{ props.option.applicant.company_name }}</span>
                  </template>
                  <span slot="noResult">Oops! No Factoring Application found. </span>
                </multiselect>
                <div
                  v-if="modalSubmit && $v.formData2.factoring_application.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData2.factoring_application.required"
                    >Factoring Application is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Document Date</label>
              <div class="col-12">
                <date-picker
                    v-model="formEditData2.document_date"
                    type="date"
                    value-type="format"
                    format="MMM DD, YYYY"
                    placeholder="Date"
                    :disabled="disabled"
                    :disabled-date="notBeforeToday"
                    :editable="false"
                  ></date-picker>
                  <div
                  v-if="modalSubmit && $v.formEditData2.document_date.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formEditData2.document_date.required"
                    >Document Date is required.</span
                  >
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Type of Letter of Support</label>
              <div class="col-12">
                <span v-for="(v,index) in type_for_term_sheet" :key="index">
                <input type="radio" v-model="formEditData2.type" :value="v.value" class="btn-check" name="document_type" :id="index" autocomplete="off">
                <label class="btn btn-outline-primary me-1" :class="{'btn-primary':formEditData2.type==v.value}" :for="index">{{v.name}}</label>
                </span>
                <div
                  v-if="modalSubmit && $v.formEditData2.type.$error"
                  class="invalid-feedback d-block"
                >
                  <span v-if="!$v.formEditData2.type.required"
                    >Document type is required.</span
                  >
                </div>
              </div>
            </div>
            
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Subject</label>
              <div class="col-12">

                <input
                    v-model.trim="formEditData2.subject"
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                    name="subject"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData2.subject.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formEditData2.subject.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData2.subject.required"
                       >Subject is required.</span
                       >
                 </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Documents (Optional) <small class="d-block text-muted fw-normal">If you manually upload the document, the bot will not automatically generate it.</small></label>
              <div class="col-12">
                <div class="row mb-0">
                <div
                    class="col-xl-6"
                    v-for="(value, index) in formEditData2.attachment"
                    :key="index"
                  >
                    <div class="card border shadow-none  border-2 bg-white mb-lg-0 mb-lg-2">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-4">
                            <div class="avatar-sm">
                              <span
                                class="avatar-title bg-soft-primary text-primary font-size-22 rounded-circle"
                                :class="{
                                  'bg-soft-success text-success':
                                    value.status == 1,
                                }"
                              >
                              <i class="uil uil-file-alt"></i>
                              </span>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center">
                            <div class="">
                              <div class="d-flex w-100">
                                <input type="text" class="form-control font-size-15 mb-1 py-1 fw-medium bg-light border-bottom border-dark" v-model="value.name" v-if="index>9" >
                                <h5 class="font-size-15 mb-1 fw-medium" v-else>
                                {{ value.name }}
                                </h5>
                              </div>
                              <p class="text-success" v-if="value.status == 1">
                                <i
                                  class="mdi mdi-check-circle text-success"
                                ></i>
                                Completed
                                <a
                                  :href="value.files"
                                  class="ms-1"
                                  v-b-tooltip.hover
                                  :title="value.file_name"
                                  v-if="value.files"
                                  target="_blank"
                                >
                                  <i class="uil uil-import font-size-15"></i>
                                  {{ value.file_name }} ({{ value.file_size }})
                                </a>
                                <a href="javascript:void(0)" class="text-danger ms-1" v-if="value.status == 1" @click="
                                    value.status=0,
                                    value.files= '',
                                    value.file_name= '',
                                    value.file_size= ''
                                "><span><i class="mdi mdi-trash-can-outline font-size-16"></i> Remove</span>
                                </a>
                              </p>
                              <p class="text-warning" v-if="value.status == 2">
                                <i
                                  class="mdi mdi-clock-time-nine-outline text-warning"
                                ></i>
                                Uploading
                                <span class="text-muted ms-1"
                                  >{{ value.file_name }} ({{
                                    value.file_size
                                  }})</span
                                >
                              </p>
                              <p class="text-muted" v-if="value.status == 0">
                                <i class="mdi mdi-alert-circle text-danger"></i>
                                Pending
                              </p>

                              <!-- <div class="placeholder-glow">
                                          <span class="placeholder col-12 rounded"></span>
                                         </div> -->
                              <div v-if="buttonLoading && value.status == 2">
                                <div
                                  class="Animated progress rounded bg-white"
                                  style="height: 26px"
                                  role="progressbar"
                                  :aria-valuenow="uploadProgress"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13"
                                    :style="`width: ${uploadProgress}%`"
                                  >
                                    {{ uploadProgress }}%
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <input
                                  class="form-control mb-0"
                                  accept="application/pdf"
                                  type="file"
                                  :disabled="buttonLoading"
                                  :id="value.id"
                                  @change="onChangePDF2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-12 col-form-label">Completed Documents<small class="d-block text-muted fw-normal">Please upload the completed document, once the document process is completed.</small></label>
              <div class="col-12">
                <div class="row mb-0">
                <div
                    class="col-xl-6"
                    v-for="(value, index) in formEditData2.completed_attachment"
                    :key="index"
                  >
                    <div class="card border shadow-none  border-2 bg-white mb-lg-0 mb-lg-2">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="flex-shrink-0 me-4">
                            <div class="avatar-sm">
                              <span
                                class="avatar-title bg-soft-primary text-primary font-size-22 rounded-circle"
                                :class="{
                                  'bg-soft-success text-success':
                                    value.status == 1,
                                }"
                              >
                              <i class="uil uil-file-alt"></i>
                              </span>
                            </div>
                          </div>
                          <div class="flex-grow-1 align-self-center">
                            <div class="">
                              <div class="d-flex w-100">
                                <input type="text" class="form-control font-size-15 mb-1 py-1 fw-medium bg-light border-bottom border-dark" v-model="value.name" v-if="index>9" >
                                <h5 class="font-size-15 mb-1 fw-medium" v-else>
                                {{ value.name }}
                                </h5>
                              </div>
                              <p class="text-success" v-if="value.status == 1">
                                <i
                                  class="mdi mdi-check-circle text-success"
                                ></i>
                                Completed
                                <a
                                  :href="value.files"
                                  class="ms-1"
                                  v-b-tooltip.hover
                                  :title="value.file_name"
                                  v-if="value.files"
                                  target="_blank"
                                >
                                  <i class="uil uil-import font-size-15"></i>
                                  {{ value.file_name }} ({{ value.file_size }})
                                </a>
                                <a href="javascript:void(0)" class="text-danger ms-1" v-if="value.status == 1" @click="
                                    value.status=0,
                                    value.files= '',
                                    value.file_name= '',
                                    value.file_size= ''
                                "><span><i class="mdi mdi-trash-can-outline font-size-16"></i> Remove</span>
                                </a>
                              </p>
                              <p class="text-warning" v-if="value.status == 2">
                                <i
                                  class="mdi mdi-clock-time-nine-outline text-warning"
                                ></i>
                                Uploading
                                <span class="text-muted ms-1"
                                  >{{ value.file_name }} ({{
                                    value.file_size
                                  }})</span
                                >
                              </p>
                              <p class="text-muted" v-if="value.status == 0">
                                <i class="mdi mdi-alert-circle text-danger"></i>
                                Pending
                              </p>

                              <!-- <div class="placeholder-glow">
                                          <span class="placeholder col-12 rounded"></span>
                                         </div> -->
                              <div v-if="buttonLoading && value.status == 2">
                                <div
                                  class="Animated progress rounded bg-white"
                                  style="height: 26px"
                                  role="progressbar"
                                  :aria-valuenow="uploadProgress"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13"
                                    :style="`width: ${uploadProgress}%`"
                                  >
                                    {{ uploadProgress }}%
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <input
                                  class="form-control mb-0"
                                  accept="application/pdf"
                                  type="file"
                                  :disabled="buttonLoading"
                                  :id="value.id"
                                  @change="onChangeCompletePDF2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Recent Notes</label>
              <div class="col-12" v-if="formEditData2.note_logs.length">
                <ol class="activity-feed bg-light mb-0 p-4 rounded-3" data-simplebar style="max-height: 336px;">
                  <li class="feed-item" v-for="(value, index) in formEditData2.note_logs" :key="index">
                      <div class="feed-item-list">
                          <p class="text-muted mb-1 font-size-13 text-capitalize">
                            {{ convertDate2DayWeekandDate(value.date_created)}} 
                          </p>
                          <p class="mt-0 mb-0">
                             {{value.notes}}
                          </p> 
                          <em class="mt-1 font-size-13 text-muted">Updated by {{value.created_by}} 
                          </em> 
                      </div>
                  </li>
                </ol>
              </div>
              <div class="col-12" v-else>
                <div class="text-center bg-light mb-0 p-4 rounded-3 text-muted">
                    No Data Available
                </div>
              </div>
            </div>
            
            <div class="mb-2 row">
              <label class="col-12 col-form-label">Add New Note</label>
              <div class="col-12">
                <textarea class="form-control" placeholder="Optional" id="notes" v-model.trim="formEditData2.notes" rows="3"></textarea>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-12 col-lg col-form-label">Status</label>
              <div class="col-12">
                <select class="form-select" v-model="formEditData2.status" :disabled="disabled">
                  <option value="draft">Draft</option>
                  <option value="sent">Sent</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
            <hr>
            <div class="d-grid gap-2 d-md-block text-end">
              <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-term-sheet-edit'].hide()">Cancel</button>
              
              <button type="button" class="btn fw-medium btn-info" @click="updateRequest2" :disabled="modalLoading">
                <span v-if="modalLoading"> Updating...</span>
                <span v-else> Update </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          
        </div>
      </b-modal>
      <Common ref="commonFunc" />
    </div>
  </template>
  <script>

  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { required} from "vuelidate/lib/validators";
  import DatePicker from "vue2-datepicker";
  /**
   * Starter page
   */

  export default {
    components: { 
      Multiselect,
      DatePicker,
      Common
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
      return {
        updateData:{},
        uploadProgress: 0,
        accessToken:'',
        accessEmail:'',
        accessPhone:'',
        accessUsername:'',
        applicantList:[],
        factoringApplicationList:[],
        loading:false,
        disabled:false,
        modalSubmit:false,
        modalLoading:false,
        buttonLoading:false,
        status:["draft","sent","approved","rejected"],
        type_of_letter_support:[
          {
            "name": "Quotation",
            "value": "quotation",
          },
          {
            "name": "General",
            "value": "general",
          },
          {
            "name": "CA40",
            "value": "CA40",
          }
        ],
        type_for_term_sheet:[
          {
            "name": "Pre Factoring",
            "value": "pre_factoring",
          },
          {
            "name": "Factoring",
            "value": "factoring",
          },
          {
            "name": "Post Factoring",
            "value": "post_factoring",
          }
        ],
        formData: {
          applicant:"",
          type:"",
          subject:"",
          document_date:"",
          status:"draft",
          notes: "",
          files: [
          {
            id: 1,
            name: "Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
          },
          {
            id: 2,
            name: "Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
          }
        ]
        },
        formData2: {
          factoring_application:"",
          type:"",
          subject:"",
          document_date:"",
          status:"draft",
          notes: "",
          files: [
          {
            id: 1,
            name: "Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          },
          {
            id: 2,
            name: "Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          }
        ]
        },
        formEditData:{
          account_db_code: "",
          applicant:"",
          type:"",
          subject:"",
          document_date:"",
          status:"",
          note_logs:[],
          attachment:[],
          completed_attachment:[{
            id: 1,
            name: "Completed Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          },
          {
            id: 2,
            name: "Completed Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          }],
          notes: "",
        },
        formEditData2:{
          account_db_code: "",
          factoring_application:"",
          type:"",
          subject:"",
          document_date:"",
          status:"",
          note_logs:[],
          attachment:[],
          completed_attachment:[{
            id: 1,
            name: "Completed Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          },
          {
            id: 2,
            name: "Completed Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          }],
          notes: "",
        }
      };
    },
    validations: {
      formData: {
        applicant:{
          required,
        },
        document_date:{
          required,
        },
        type:{
          required,
        },
        subject:{
          required,
        },
        status:{
          required
        }
      },
      formData2: {
        factoring_application:{
          required,
        },
        document_date:{
          required,
        },
        type:{
          required,
        },
        subject:{
          required,
        },
        status:{
          required
        }
      },
      formEditData: {
        applicant:{
          required,
        },
        document_date:{
          required,
        },
        type:{
          required,
        },
        subject:{
          required,
        },
        status:{
          required
        }
      },
      formEditData2: {
        factoring_application:{
          required,
        },
        document_date:{
          required,
        },
        type:{
          required,
        },
        subject:{
          required,
        },
        status:{
          required
        }
      },

    },
    middleware: "authentication",
    async mounted(){
     
    //   this.title = PageTitle
    //   this.items[1].text = PageTitle
    //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    //   // this.$refs.main.changeVerticalTopBar("bill",true)
    //   // this.$refs.main.setShowFooterCert(false)
    //   // this.$refs.main.setPageTitle('title')
    //   console.log( this.$refs.commonFunc.getPhone())
    //   this.accessToken = this.$refs.commonFunc.getToken()
    //   this.accessUsername = this.$refs.commonFunc.getUsername()
    //   await this.getData();
      
    }, 
    created(){
     
    },
    methods:{
      convertDate2DayWeekandDate(value) {
          // Convert value to Date object
          const date = new Date(value);
          // Current time
          const now = new Date();
          // Time difference in milliseconds
          const diff = now - date;
          // Convert milliseconds to minutes
          const diffMinutes = Math.floor(diff / (1000 * 60));
          // Convert minutes to hours
          const diffHours = Math.floor(diff / (1000 * 60 * 60));
          // Convert hours to days
          const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
          // Convert days to weeks
          const diffWeeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));

          if (diffMinutes < 1) {
              return 'just now';
          } else if (diffMinutes < 60) {
              return `${diffMinutes} mins ago`;
          } else if (diffHours < 24) {
              return `${diffHours} hr ago`;
          } else if (diffHours < 48) {
              return '1 day ago';
          } else if (diffDays < 7) {
              return `${diffDays} days ago`;
          } else if (diffWeeks < 2) {
              return '1 week ago';
          } else {
              // Return actual date for anything older than a week
              const options = { year: 'numeric', month: 'long', day: 'numeric' };
              return date.toLocaleDateString(undefined, options);
          }
      },
      async onChangePDF(e) {
        console.log(e.target.id);
        let file = e.target.files[0];
        console.log(file);
        if (this.formEditData.account_db_code==''){
          this.formData.files.forEach((element) => {
            if (element.id == e.target.id) {
              element.status = 2;
              element.file_name = file.name;
              element.file_size = this.convertFileSize(file.size);
              element.files = file;
              this.uploadFile(element,'letter_support_docs');
            }
          });
        }else{
          this.formEditData.attachment.forEach((element) => {
            if (element.id == e.target.id) {
              element.status = 2;
              element.file_name = file.name;
              element.file_size = this.convertFileSize(file.size);
              element.files = file;
              this.uploadFile(element,'letter_support_docs');
            }
          });
        }
        // this.formSuccessData.transactionReceipt = file
        // this.formSuccessData.transactionReceiptFile = file
      },
      async onChangeCompletePDF(e) {
        console.log(e.target.id);
        let file = e.target.files[0];
        console.log(file);
        this.formEditData.completed_attachment.forEach((element) => {
          if (element.id == e.target.id) {
            element.status = 2;
            element.file_name = file.name;
            element.file_size = this.convertFileSize(file.size);
            element.files = file;
            this.uploadFile(element,'letter_support_docs');
          }
        });
      },
      async onChangePDF2(e) {
        console.log(e.target.id);
        let file = e.target.files[0];
        console.log(file);
        if (this.formEditData2.account_db_code==''){
          this.formData2.files.forEach((element) => {
            if (element.id == e.target.id) {
              element.status = 2;
              element.file_name = file.name;
              element.file_size = this.convertFileSize(file.size);
              element.files = file;
              this.uploadFile(element,'term_sheet_docs');
            }
          });
        }else{
          this.formEditData2.attachment.forEach((element) => {
            if (element.id == e.target.id) {
              element.status = 2;
              element.file_name = file.name;
              element.file_size = this.convertFileSize(file.size);
              element.files = file;
              this.uploadFile(element,'term_sheet_docs');
            }
          });
        }
        // this.formSuccessData.transactionReceipt = file
        // this.formSuccessData.transactionReceiptFile = file
      },
      async onChangeCompletePDF2(e) {
        console.log(e.target.id);
        let file = e.target.files[0];
        console.log(file);
        this.formEditData2.completed_attachment.forEach((element) => {
          if (element.id == e.target.id) {
            element.status = 2;
            element.file_name = file.name;
            element.file_size = this.convertFileSize(file.size);
            element.files = file;
            this.uploadFile(element,'letter_support_docs');
          }
        });
      },
      inputNumberOnly(event, parentModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this.formData[parentModel] = numericValue;
      },
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
       
      },
      showModal(value){
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        if (value=="letterSupport"){
          this.$refs['modal-letter-support-create'].show()
          this.formData.document_date = this.todayDate();
          this.formData.type = "general"
          this.getApplicant();
        }
        if (value=="termSheet"){
          this.$refs['modal-term-sheet-create'].show()
          this.formData2.document_date = this.todayDate();
          this.formData2.type = "pre_factoring"
          this.getFactoringApplication();
        }
      },
      showEditModal(value){
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        if (value=="letterSupport"){
          this.$refs['modal-letter-support-edit'].show()
          this.formEditData.account_db_code = this.updateData.letter_db_code;
          this.formEditData.applicant = this.updateData.applicant_info;
          this.formEditData.document_date = this.updateData.document_date;
          this.formEditData.type = this.updateData.type_of_support;
          this.formEditData.subject = this.updateData.subject;
          this.formEditData.note_logs = this.updateData.notes;
          this.formEditData.attachment = this.updateData.files;
          if (this.updateData.complete_files.length > 0){
            this.formEditData.completed_attachment = this.updateData.complete_files;
          }
          this.formEditData.status = this.updateData.status
        }
        if (value=="termSheet"){
          this.$refs['modal-term-sheet-edit'].show()
          this.formEditData2.account_db_code = this.updateData.account_db_code;
          this.formEditData2.factoring_application = this.updateData.factoring_application;
          this.formEditData2.document_date = this.updateData.document_date;
          this.formEditData2.type = this.updateData.type_of_term_sheet;
          this.formEditData2.subject = this.updateData.subject;
          this.formEditData2.note_logs = this.updateData.note_logs;
          this.formEditData2.attachment = this.updateData.attachment;
          if (this.updateData.completed_attachment.length > 0){
            this.formEditData2.completed_attachment = this.updateData.completed_attachment;
          }
          this.formEditData2.status = this.updateData.status
        }
      },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      todayDate() {
        // Get today's date
        var today = new Date();
        // Define month names
        var monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        // Get the month, day, and year
        var month = monthNames[today.getMonth()];
        var day = today.getDate();
        var year = today.getFullYear();

        // Format the date
        var formattedDate = month + " " + (day < 10 ? "0" : "") + day + ", " + year;
        return formattedDate;
      },
      updateApplicantInfo(){
        console.log("updateApplicantInfo")
      },
      updateFactoringApplication(){
        console.log("updateFactoringApplication")
      },
      getApplicant() {
        console.log("getApplicant");
        this.$Progress.start();
        this.loading = true;
        this.disabled = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "9999");
        bodyFormData.append("keyword", "");
        bodyFormData.append("sortBy", "");
        bodyFormData.append("dateStart", "");
        bodyFormData.append("dateEnd", "");
        axios({
          method:"post",
          url: appConfig.APIHost + 'controller/applicant/getAllApplicantList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              this.applicantList = []
              resData.data.forEach((element) => {
                this.applicantList.push(element);
              });
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      },
      getFactoringApplication() {
        console.log("getFactoringApplication");
        this.$Progress.start();
        this.loading = true;
        this.disabled = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "9999");
        bodyFormData.append("keyword", "");
        bodyFormData.append("paymentMode","");
        bodyFormData.append("status", "");
        bodyFormData.append("sortBy", "");
        bodyFormData.append("dateStart", "");
        bodyFormData.append("dateEnd","");
        bodyFormData.append("originator","");
        bodyFormData.append("natureOfContract", "")
        bodyFormData.append("requiringMySignature","")
        axios({
          method:"post",
          url: appConfig.APIHost + 'controller/application/getAllApplicationList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              this.factoringApplicationList = []
              resData.data.forEach((element) => {

                var existingObject = element;
                existingObject.keyword =  `${element.application_id} ${element.applicant.company_name}`
                
                this.factoringApplicationList.push(existingObject);
              });
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      },  
      resetModal() {
        this.uploadProgress=0;
        this.applicantList=[];
        this.factoringApplicationList=[];
        this.disabled = false;
        this.modalSubmit = false;
        this.modalLoading = false;
        this.formData= {
          applicant:"",
          type:"general",
          subject:"",
          document_date:"",
          status:"draft",
          files: [
          {
            id: 1,
            name: "Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
          },
          {
            id: 2,
            name: "Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
          }]
        }
        this.formData2= {
          factoring_application:"",
          type:"pre_factoring",
          subject:"",
          document_date:"",
          status:"draft",
          files: [
          {
            id: 1,
            name: "Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
          },
          {
            id: 2,
            name: "Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: "",
          }]
        }
        this.formEditData= {
          account_db_code: "",
          applicant:"",
          type:"",
          subject:"",
          document_date:"",
          status:"",
          note_logs:[],
          attachment:[],
          completed_attachment:[{
            id: 1,
            name: "Completed Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          },
          {
            id: 2,
            name: "Completed Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          }],
          notes: "",
        }
        this.formEditData2= {
          account_db_code: "",
          factoring_application:"",
          type:"",
          subject:"",
          document_date:"",
          status:"",
          note_logs:[],
          attachment:[],
          completed_attachment:[{
            id: 1,
            name: "Completed Document (1)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          },
          {
            id: 2,
            name: "Completed Document (2)",
            status: 0,
            files: "",
            file_name: "",
            file_size: ""
          }],
          notes: "",
        }
      },
      addRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          bodyFormData.append("applicant", JSON.stringify(this.formData.applicant))
          bodyFormData.append("documentDate",  this.formData.document_date);
          bodyFormData.append("typeOfSupport",  this.formData.type);
          bodyFormData.append("subject",  this.formData.subject);
          bodyFormData.append("files",  JSON.stringify(this.formData.files));
          bodyFormData.append("notes",  this.formData.notes);
          bodyFormData.append("status",  this.formData.status);
  
          axios({
              method: "post",
              url: appConfig.APIHost + "controller/letterOfSupport/createNewLetterOfSupport",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'New Letter of Support Document Added',
                  html: `Your document has been successfully created! If you require an automatically generated document attachment, please note that it may take some time to generate.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-letter-support-create'].hide()
                  console.log(this.$route.name)
                  if (this.$route.name =='Letter of Support'){
                    this.$emit('callParentFunction');
                  }else{
                    this.$router.push({
                      name: "Letter of Support",
                   });
                  }
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateRequest(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        if (this.$v.formEditData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          bodyFormData.append("letterDbCode",this.formEditData.account_db_code)
          bodyFormData.append("applicant", JSON.stringify(this.formEditData.applicant))
          bodyFormData.append("documentDate",  this.formEditData.document_date);
          bodyFormData.append("typeOfSupport",  this.formEditData.type);
          bodyFormData.append("subject",  this.formEditData.subject);
          bodyFormData.append("files",  JSON.stringify(this.formEditData.attachment));
          bodyFormData.append("completedDocs",  JSON.stringify(this.formEditData.completed_attachment));
          bodyFormData.append("notes",  this.formEditData.notes);
          bodyFormData.append("status",  this.formEditData.status);
          axios({
              method: "post",
              url: appConfig.APIHost + "controller/letterOfSupport/editNewLetterOfSupport",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Letter of Support Document Updated',
                  html: `Your document has been successfully updated! `,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-letter-support-edit'].hide()
                  console.log(this.$route.name)
                  if (this.$route.name =='Letter of Support'){
                    this.$emit('callParentFunction');
                  }else{
                    this.$router.push({
                      name: "Letter of Support",
                   });
                  }
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      
      addRequest2(){
        this.modalSubmit = true
        this.$v.formData2.$touch();
        if (this.$v.formData2.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          bodyFormData.append("factoringApplication", JSON.stringify(this.formData2.factoring_application))
          bodyFormData.append("documentDate",  this.formData2.document_date);
          bodyFormData.append("typeOfTermSheet",  this.formData2.type);
          bodyFormData.append("subject",  this.formData2.subject);
          bodyFormData.append("files",  JSON.stringify(this.formData2.files));
          bodyFormData.append("notes",  this.formData2.notes);
          bodyFormData.append("status",  this.formData2.status);
  
          axios({
              method: "post",
              url: appConfig.APIHost + "controller/termSheet/createNewTermSheet",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'New Term Sheet Document Added',
                  html: `Your document has been successfully created! If you require an automatically generated document attachment, please note that it may take some time to generate.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-term-sheet-create'].hide()
                  console.log(this.$route.name)
                  if (this.$route.name =='Term Sheet'){
                    this.$emit('callParentFunction');
                  }else{
                    this.$router.push({
                      name: "Term Sheet",
                   });
                  }
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },

      updateRequest2(){
        this.modalSubmit = true
        this.$v.formEditData2.$touch();
        if (this.$v.formEditData2.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          bodyFormData.append("termDbCode",this.formEditData2.account_db_code)
          bodyFormData.append("factoringApplication", JSON.stringify(this.formEditData2.factoring_application))
          bodyFormData.append("documentDate",  this.formEditData2.document_date);
          bodyFormData.append("typeOfTermSheet",  this.formEditData2.type);
          bodyFormData.append("subject",  this.formEditData2.subject);
          bodyFormData.append("files",  JSON.stringify(this.formEditData2.attachment));
          bodyFormData.append("completedDocs",  JSON.stringify(this.formEditData2.completed_attachment));
          bodyFormData.append("notes",  this.formEditData2.notes);
          bodyFormData.append("status",  this.formEditData2.status);
          axios({
              method: "post",
              url: appConfig.APIHost + "controller/termSheet/editTermSheet",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              this.returnData = []
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Term Sheet Document Updated',
                  html: `Your document has been successfully updated! `,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-term-sheet-edit'].hide()
                  console.log(this.$route.name)
                  if (this.$route.name =='Term Sheet'){
                    this.$emit('callParentFunction');
                  }else{
                    this.$router.push({
                      name: "Term Sheet",
                   });
                  }
                })
                
              } 
              else if (resData.status == 440){
                // Swal.fire(
                //     {
                //     icon: 'error',
                //     title: 'Oops...',
                //     html: `${resData.message}.`,
                //     confirmButtonColor: '#222',
                //     confirmButtonText: this.$t('siteLang.Done'),
                //   })
                //   localStorage.clear();
                //   this.$router.push({
                //       path: "/login",
                // });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      convertFileSize(fileSize) {
        var formattedFileSize = "";

        if (fileSize < 1024) {
          formattedFileSize = fileSize + " bytes";
        } else if (fileSize < 1048576) {
          // 1024 * 1024
          formattedFileSize = (fileSize / 1024).toFixed(2) + " KB";
        } else if (fileSize < 1073741824) {
          // 1024 * 1024 * 1024
          formattedFileSize = (fileSize / 1048576).toFixed(2) + " MB";
        } else {
          formattedFileSize = (fileSize / 1073741824).toFixed(2) + " GB";
        }
        return formattedFileSize;
      },
      uploadFile(value, mediaPath) {
        this.$Progress.start();
        this.buttonLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("mediaPath", mediaPath);
        //bodyFormData.append("id", value.id);
        bodyFormData.append("media", value.files);
        //bodyFormData.append("size", value.file_size);
      // bodyFormData.append("name", value.file_name);

        axios({
          method: "post",
          url: appConfig.APIHost + 'controller/media/saveMediaFile',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData);
              value.status = 1;
              value.checkYesNo = "checked";
              value.files = resData.media_path;
              console.log("uploaded");
              //upload to the server temporary place first and you return id to me, after that i submit the form you move the data to ori place
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.buttonLoading = false;
            this.uploadProgress = 0;
            this.submitted = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.buttonLoading = false;
            this.uploadProgress = 0;
            this.submitted = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
  
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.formData.apiID = data.apiID;
            this.formData.apiHash = data.apiHash;
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
  
    }
  };
  </script>
<style scoped>
.min-height{
  min-height: 200px;
}
.activity-feed .feed-item{
  border-left: 2px solid #99a5bd;
}
.activity-feed .feed-item:after{
  content: "";
display: block;
position: absolute;
top: 4px;
left: -6px;
width: 10px;
height: 10px;
border-radius: 50%;
border: 2px solid #ee7322;
background-color: rgb(245, 247, 250);}
</style>